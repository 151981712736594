
























import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/layouts/Header.vue';
import Section from '@/components/layouts/Section.vue';
import { siteCandidateEndPoint, siteCompanyEndPoint } from "@/core/endpoint";

@Component({
  components: {
    'app-header': Header,
    Section,
  },
})
export default class HeaderContent extends Vue {

  public empresaPage() {
    this.$router.push({ path: '/empresa', query: {lang: this.$i18n.locale}});
  }

  public goRegister() {
    const frontBase = siteCandidateEndPoint;
    window.location.href = `${frontBase}/register?lang=${this.$i18n.locale}`;
  }
}
