















import { Component, Vue } from 'vue-property-decorator';
import FeatureCardsSection from '@/views/candidato/components/sections/FeatureCardsSection.vue';
import VideoCurriculo from '@/views/candidato/components/sections/VideoCurriculo.vue';
import EntrevistasGravadas from '@/views/candidato/components/sections/EntrevistasGravadas.vue';
import EntrevistaCegas from '@/views/candidato/components/sections/EntrevistaCegas.vue';
import HeaderContent from '@/views/candidato/components/sections/HeaderContent.vue';
import PorqueJobecam from '@/views/candidato/components/sections/PorqueJobecam.vue';
import Depoimentos from '@/views/candidato/components/sections/Depoimentos.vue';
import Duvidas from '@/components/duvidas/Duvidas.vue';
import Contato from '@/components/contato/Contato.vue';
import LoginModal from '@/components/login/LoginModal.vue';

@Component({
  components: {
    FeatureCardsSection,
    VideoCurriculo,
    EntrevistasGravadas,
    EntrevistaCegas,
    HeaderContent,
    PorqueJobecam,
    Depoimentos,
    Duvidas,
    Contato,
    LoginModal
  },
})
export default class Home extends Vue {
  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public created() {
    this.cleanSession()
  }
}
