
















import { Component, Vue } from 'vue-property-decorator';
import TentativaRegistro from './section-funcionalidades-jobecam/TentativaRegistro.vue';

@Component({
  components: {
    TentativaRegistro
  },
})
export default class SectionRecrutamento extends Vue {
  public openModal() {
    // this.$emit('diagnostico-show',true);
    if(this.$i18n.locale=='pt-BR'){
       window.open('https://forms.gle/JM3Xj3s3epnw4YFb8', '_blank');
    }else{
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSewV3WLnjf8-OyNCLwn6EBVkZr_aDwPyxNWZmeJy1ZudDg1WA/viewform', '_blank');
    }
  }
  public done() {
    this.$bvModal.hide('modal-recrutamento-pdf')
  }
}
