




























import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    'app-section': Section,
    Carousel,
    Slide
  },
})
export default class SectionDepoimentos extends Vue {
  public depoimentos: any[] = [];

  public created() {
    this.depoimentos = [
      {
        text: 'A jornada de transformação da Oracle continua e ainda existem percalços como a diversidade da força de trabalho, que buscamos resolver com métodos como o recrutamento às cegas da Jobecam. Esta é uma parceria que cresce junto com nossa transformação.',
        who: 'Rodrigo Galvão, Presidente Oracle Brasil'
      },
      {
        text: 'Em 3 meses de utilização da Jobecam, os resultados foram surpreendentes: Aumento do número de contratações em 65%, otimizando o de tempo dos recrutadores. Redução dos custos de contratação em 15mil  no período, 60mil por ano! Aumento de 62% em performance, devido ao match entre o candidato e empresa, gerando lucratividade de 700mil reais no período. Uma projeção de 2,8M ao ano!.Só sucesso!',
        who: 'Empresa no ramo de Gestão Imobiliária com 44 anos de mercado.'
      },
      {
        text: 'Fazer uma triagem de perfis através da entrevista gravada da Jobecam ajuda muito a reduzir o meu tempo de entrevistas e elimina candidatos fora do perfil. Após isto, conseguir fazer nossas dinâmicas de grupo online, tudo por vídeo e ainda envolver o gestor, nos economiza muito tempo e deixa o processo mais digital e ágil. É um sonho!',
        who: 'Empresa do ramo Bancário'
      }
    ];
  }
}
