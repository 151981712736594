






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import { EPeriodoPlano } from '@/core/Enums/EPeriodoPlano';

@Component
export default class PlanCard extends Vue {
  private EPeriodoPlano_Anual: EPeriodoPlano = EPeriodoPlano.Anual;

  @Prop() private headerText!: string;
  @Prop() private bodyTitle!: string;
  @Prop() private textColor!: string;
  @Prop() private headerBgColor!: string;
  @Prop() private jobcoins!: string;
  @Prop() private planName!: string;
  @Prop() private price!: string;
  @Prop() private price_usd!: string;
  @Prop() private planType!: string;
  @Prop() private periodo!: EPeriodoPlano;
  @Prop() private buttonColor!: string;
  @Prop() private textButton!: string;
  @Prop() private listjson!: string;
  @Prop() private planId!: string;
  @Prop({ default: false }) private showBtn!: boolean;
  @Prop({ default: false }) private free!: boolean;
  @Prop({ default: false }) private popular!: boolean;
  @Prop({ default: false }) private promocional!: boolean;

  public avaliablelist: string[]|null = null;

  created(){
    console.log(this.$i18n.locale);
  }

  private get style() {
    let style = '';

    if (this.textColor) {
      style += `color: ${this.textColor}; `;
    }

    return style;
  }

  get priceFormatted(){
    if(this.$i18n.locale === 'pt-BR'){
      return parseFloat(this.price).toFixed(2).replace(',','').replace('.',',');
    } else {
      return parseFloat(this.price_usd).toFixed(2)
    }
  }

  get listFeat() {
    if(this.listjson && this.avaliablelist == null){
      try {
        this.avaliablelist = JSON.parse(this.listjson);
      } catch(e) {
        this.avaliablelist = null;
      }
    }
    return this.avaliablelist;
  }

  public planSelected() {
    this.$gtag.event('Clique Quero', { event_category: 'Planos', event_label: (this.planName||this.jobcoins) })
    this.$emit('planSelected', this.planId, this.free)
  }
}
