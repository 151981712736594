

































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { PlanoCreditoEntity } from '@/core/models/Entities';
import { Validate } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';

import TextInput from '@/components/inputs/TextInput.vue';
import JBIugu from '@/core/payment/jb-iugu.js';
import Payment from '@/core/Services/Payments/Payment';

@Component({
  components: {
    TextInput,
  },
})
export default class CreditCardForm extends Vue {
  @Prop() private plano!: PlanoCreditoEntity;
  private payment: Payment = new Payment();

  @Validate({
    name: {
      required,
      fullName: (value: string) => {
        return -1 !== value.trim().indexOf(" ");
      }
    },
    ccNo: { required, cardNumber: Iugu.utils.validateCreditCardNumber },
    cvv: {
      required,
      cardCVV: (value, vm) => {
        const cardBrand = Iugu.utils.getBrandByCreditCardNumber(vm.ccNo);
        return Iugu.utils.validateCVV(value, cardBrand);
      },
    },
    expMonthYear: {
      required,
      cardExpDate: (value: string) => {
        if (value && -1 !== value.indexOf('/')) {
          const [mes, ano] = value.split('/');
          return Iugu.utils.validateExpiration(mes, ano);
        }
        return false;
      },
    },
  })
  private iugu = new JBIugu();

  public finalizeBuy() {
    this.$v.$touch();

    if (this.$v.$invalid)
      return;

    //separa o nome do sobrenome
    let fullname = this.iugu.name;
    let index = fullname.indexOf(" ");
    let first_name = fullname.substring(0, index);
    let last_name = fullname.substring(index + 1, fullname.length);
    this.iugu.firstname = first_name;
    this.iugu.lastname = last_name;

    let monthYear = this.iugu.expMonthYear.split("/");
    this.iugu.expMonth = monthYear[0];
    this.iugu.expYear = monthYear[1];

    return new Promise((resolve, reject) => {
      this.$emit('submitted')
      this.iugu.makeTokenRequest()
        .then(() => {
          this.makePayment()
            .then((d) => resolve(d))
            .catch((e) => reject(e));
        })
        .catch((e1) => reject(e1));
    });
  }

  public makePayment() {
    // console.log(this.iugu.tokenResponseObject)
    let data = {
      _tkn: this.iugu.tokenResponseObject.id,
      tp: 3, // Tipo de pagamento iugu
      planoId: this.plano.id
    };
    console.log(data);
    return this.payment
      .create(data)
      .then((data: any) => {
        if (this.plano!.free) {
          this.$router.push({ name: 'CadastroPlanoGratuito', query: {lang: this.$i18n.locale} });
          return;
        }
        // add payment to facebook
        if(window.fbq)
          window.fbq('track', 'Purchase', {
            content_category: "Purchase", 
            content_name: this.plano.plan_identifier, 
            currency: "BRL",
            value: this.plano.preco,
            num_items: 1
          });

        this.$router.push({ name: 'CadastroPagamentoSucesso', query: {lang: this.$i18n.locale} });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
