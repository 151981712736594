





































import { Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import { Carousel, Slide } from 'vue-carousel';

@Component({
  components: {
    'app-section': Section,
    Carousel,
    Slide
  },
})
export default class SectionElesNosEscolheram extends Vue {
  public partners: any[] = []

  public mounted() {
    this.partners = [
      {
        key: "general",
        group: "Parceiros",
        path: "/static/parceiros/comerciais/",
        object: false,
        logos: [ 
          "selo_municipal.png",
          "gupy_logo.png",
          "OGSE_Logo_Wht_Red_RGB-01.png",
          "brq.png",
          "people_strategy.jpg",
          "google-for-startups.jpg",
          "Microsoft-Logo-2012.jpg",
          "UNWomen_WePrinciples_endt_support_k_pos_rgb.png",
          "LogoCreditas_300x-8.png",
        ]
      },
      {
        key: "comercials",
        group: "Parceiros Comerciais",
        path: "/static/parceiros/comerciais/",
        object: true,
        logos: [ 
          { text: "jooble_full_horizontal_with_text.jpg", link: "https://br.jooble.org/vagas-de-emprego-recrutamento" },
          { text: "indeed.png", link: "https://www.indeed.com/" },
          { text: "recruta_simples.jpg", link: "https://www.recrutasimples.com.br/" }
        ]
      },
      {
        key: "ensino",
        group: "Instituições de Ensino Parceiras",
        path: "/static/parceiros/ensino/",
        object: false,
        logos: [
          "Logo_Anhembi_PNG.png",
          "FMU.png",
          "IBMRLogo.png",
          "logo-fadergs.png",
          "logo-fpb.png",
          "Logo_UNIFG.png",
          "potiguar.png",
          "CEE.png",
          "UNIFACS.png",
          "uniritter.png"
        ]
      }
    ]
  }

}
