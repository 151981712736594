































import { Component, Prop, Vue } from "vue-property-decorator";
import Section from '../Section.vue';
const VueScrollTo = require('vue-scrollto');
import RadialProgress from '@/components/progress/ProgressProfile.vue'

@Component({
  components: {
    RadialProgress,
    'app-section': Section
  },
})
export default class SectionComoAjudar extends Vue {
  public options!: any;

  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public goCadastro() {
    this.$bvModal.show('modal-video-tour')
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }
}
