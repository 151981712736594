





















































































import { Component, Vue } from 'vue-property-decorator';
import Section from '../Section.vue';
import IconCard from './IconCard.vue';
const VueScrollTo = require('vue-scrollto');

@Component({
  components: {
    'app-section': Section,
    IconCard,
  },
})
export default class SectionFuncionalidadesJobecam extends Vue {
  public options!: any;

  constructor() {
    super();
    this.options = {
      container: 'body',
      easing: 'ease-in',
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public goCadastro() {
    this.$bvModal.show('modal-video-tour')
    VueScrollTo.scrollTo("#nossos-planos", 500, this.options);
  }
}
