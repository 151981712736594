





































import { Component, Vue } from 'vue-property-decorator';
import SocialMedia from '@/views/candidato/components/SocialMedia.vue';
import LangSelector from '@/components/LangSelector.vue';
import CookieLaw from 'vue-cookie-law';
import {
  siteCandidateEndPoint,
  baseEndPoint,
  clientId,
  siteCompanyEndPoint
} from "@/core/endpoint";

@Component({
  components: {
    SocialMedia,
    LangSelector,
    CookieLaw,
  },
})
export default class Footer extends Vue {
  public candidateURL = siteCandidateEndPoint;
}
