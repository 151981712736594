






































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import TextArea from '@/components/inputs/TextArea.vue';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";

import ContatoService from '@/core/Services/Basic/Contato';
import { EmpresaTrialAccount } from '@/core/models/Entities';
import Alert from '@/components/alerts/Alert.vue';
import TentativaRegistroService from '@/core/Services/Empresa/TentativaRegistro';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';

@Component({
  components: {
    PlanCard,
    TextArea,
    TextInput,
    TelInput
  },
})
export default class FormPlanoCustomizado extends Vue {
public promise: Promise<any> |null = null;
  private contatoService: ContatoService = new ContatoService();
  private alert: Alert = new Alert();
  private trialAccount?: EmpresaTrialAccount;
  private tentativaCadastroService = new TentativaRegistroService();
  public question1: any[] = [];
  public question2: any[] = [];
  private avgJobList: string[] = [];
  
  @Validate({ required })
  private responsavel: string = '';

  @Validate({ required, email })
  private empresaEmail: string = '';

  @Validate({ required })
  private empresaTelefone: string = '';

  @Validate({ required })
  private mensagem = '';

  @Validate({ required })
  private resp1 = '';

  @Validate({ required })
  private resp2 = '';

  @Validate({ required })
  private avgJobs = '';

  @Validate({ required })
  private cargo = '';

  public mounted() {
    this.loadDataFromSession();


    this.question1 = [
      { text: ""+this.$t("Selecione"), value: null },
      { text: ""+this.$t("Nada relevante"), value: "Nada relevante" },
      { text: ""+this.$t("Relevante, mas não sabemos por onde começar e precisamos de ajuda"), value: "Relevante, mas não sabemos por onde começar e precisamos de ajuda" },
      { text: ""+this.$t("Relevante e já sabemos por onde queremos começar a trabalhar"), value: "Relevante e já sabemos por onde queremos começar a trabalhar" },
      { text: ""+this.$t("Relevante e já começamos a trabalhar"), value: "Relevante e já começamos a trabalhar" },
      { text: ""+this.$t("Relevante e já temos um trabalho estruturado em DEI"), value: "Relevante e já temos um trabalho estruturado em DEI" },
      { text: ""+this.$t("Não se aplica"), value: "Não se aplica" }
    ];

    this.question2 = [
      {text: ""+this.$t('TOTALMENTE CONVENCIONAL'), value: 'Usamos plataforma de emprego'},
      {text: ""+this.$t('CONVENCIONAL'), value: 'Usamos plataforma de emprego convencional, até temos um sistema mas não utilizamos de forma adequada, realizamos análise de CV manual mas utilizamos entrevista online com (skype, meet, Zoom, ect.).'},
      {text: ""+this.$t('TRANSIÇÃO PARA DIGITAL'), value: 'Adquirimos um ATS, estamos fazendo mais entrevistas online por ferramentas de vídeo, e queremos conhecer novas metodologias.'},
      {text: ""+this.$t('DIGITAL'), value: 'Usamos ATS a maioria da etapa do nosso processo seletivo é digital. Temos teste online, assessment comportamental, entrevistas por vídeo gravada, entrevistas online, entre outras.'},
    ];
    this.avgJobList = [
      this.$t('Até 2 vagas').toString(),
      this.$t('2 a 6 vagas').toString(),
      this.$t('6 a 10 vagas').toString(),
      this.$t('10 a 20 vagas').toString(),
      this.$t('mais de 20 vagas').toString(),
      this.$t('Outros').toString()
    ]
    

                        

  }

  public loadDataFromSession() {
    const dadosEmpresaString: string|null = sessionStorage.getItem('registroEmpresa');
    let dadosEmpresa: any = undefined;
    if(dadosEmpresaString) {
      try {
        dadosEmpresa = JSON.parse(dadosEmpresaString);
        console.log(dadosEmpresa);
        this.$v.responsavel.$model = dadosEmpresa.nomeResponsavel;
        this.$v.empresaEmail.$model = dadosEmpresa.email;
        this.$v.empresaTelefone.$model = dadosEmpresa.telefone;
      } catch (e) {
        console.error(e);
      }
    }
  }



  public send() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.trialAccount = {
      nomeEmpresa: this.responsavel,
      email: this.empresaEmail,
      telefone: this.empresaTelefone,
      nomeResponsavel: this.responsavel,
      lang: this.$i18n.locale,
      ebook: false
    };

    let msg =  ` [${this.mensagem}]
      Nome Responsavel [${this.responsavel}] 
      E-mail Responsavel [${this.empresaEmail}] 
      Como você vê a relevância da Diversidade, Equidade e Inclusão (DEI) na sua empresa? [${this.resp1}]
      Você considera o processo seletivo da sua empresa: [${this.resp2}]
      Cargo [${this.cargo}]
      Vaga-Mes [${this.avgJobs}]
      `;
    console.log(msg);
    const data_send = {
        nome: this.responsavel,
        email: this.empresaEmail,
        telefone: this.empresaTelefone,
        assunto: ' - Quero conversar com consultor',
        mensagem: msg
      };

      console.log(data_send)
    this.contatoService = new ContatoService();
    this.promise = this.contatoService
      .create(data_send)
      .then((data: any) => {
        this.alert.title = this.$t('Obrigado');
        this.alert.addMsg(this.$t('Seu contato foi enviado para nossa equipe, logo iremos respondê-lo.'));
        this.alert.callBoxSuccess();
        this.$emit('sended',true)
      })
      .catch((err: any) => {
        // console.log(err);
        this.$emit('sended',false)
      });
  }
}
