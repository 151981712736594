
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Cidade extends Repository {
  public optionList?: any[];

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/cidade', method: 'POST', headers: {} },
      fetch: { url: '/v1/cidade/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/cidade', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/cidade/{id}', method: 'PUT', headers: {} }
    }));
    this.optionList = [];
  }

  public async loadOptions(ufid: any) {
    if (!ufid)
      return;
    const data: any = await this.fetchAll({cid:0, ufid });
    if (data && data.cidades) {
      this.optionList = [
        {
          text: 'Selecione',
          value: ''
        }
      ];
      data.cidades.forEach((item: any) => {
        this.optionList!.push({
          text: item.cidNome,
          value: item.id,
        });
      });
    }
  }

  public cidadeByName(cid?: string): number | null {
    let id = null;
    if (this.optionList !== undefined && this.optionList.length > 0) {
      this.optionList.forEach((item: any) => {
        if (item.text.indexOf(cid) > -1) {
          id = item.value;
        }
      });
    }
    return id;
  }

}
