
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Estado extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/estado', method: 'POST', headers: {} },
                fetch: { url: '/v1/estado/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/estado', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/estado/{id}', method: 'PUT', headers: {} } }));
        this.optionList = [];
    }

    public async loadOptions(pid?: any) {
        const data: any = await this.fetchAll({pid});
        if (data && data.estados) {
            this.optionList = [
                {
                    text: 'Selecione',
                    value: ''
                }
            ];
            data.estados.forEach((item: any) => {
                this.optionList!.push({
                    text: item.ufNome,
                    value: item.id,
                    abbrev: item.ufAbrev,
                });
            });
        }
    }

    public estadoByName(uf?: string) {
        let id = null;
        if (this.optionList !== undefined && this.optionList.length > 0) {
            this.optionList.forEach((item: any) => {
                if (item.abbrev && item.abbrev.indexOf(uf) > -1) {
                    id = item.value;
                }
            });
        }
        return id;
    }

}
