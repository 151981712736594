

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Validate } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators';
import Section from '@/components/layouts/Section.vue';
import SectionHeader from './sections/SectionHeader.vue';
import PlanCard from '@/components/PlanCard.vue';
import PlanoCredito from '@/core/Services/Payments/PlanoCredito';
import SelectInput from '@/components/form/SelectInput.vue';
import {
   siteCompanyEndPoint
} from "@/core/endpoint";

import ContatoService from '@/core/Services/Basic/Contato';
import { EmpresaTrialAccount } from '@/core/models/Entities';
import Alert from '@/components/alerts/Alert.vue';
import TentativaRegistroService from '@/core/Services/Empresa/TentativaRegistro';
import TextInput from '@/components/inputs/TextInput.vue';
import TelInput from '@/components/inputs/TelInput.vue';
import CadastroEmpresaModal from '@/components/modal/CadastroEmpresaModal.vue';

@Component({
  components: {
    PlanCard,
    SelectInput,
    TextInput,
    TelInput,
    CadastroEmpresaModal
  },
})
export default class FormMonteSeuPlano extends Vue {
public promise: Promise<any> |null = null;
  private contatoService: ContatoService = new ContatoService();
  private alert: Alert = new Alert();
  private trialAccount?: EmpresaTrialAccount;
  private tentativaCadastroService = new TentativaRegistroService();
  private yesnoList: string[] = [];
  private avgJobList: string[] = [];
  private recInterviewList: string[] = [];
  
  @Validate({ required })
  private responsavel: string = '';

  @Validate({ required, email })
  private empresaEmail: string = '';

  @Validate({ required })
  private empresaTelefone: string = '';

  @Validate({ required })
  private gravada: string = '';

  @Validate({ required })
  private ascegas: string = '';

  @Validate({ required })
  private aovivo: string = '';

  @Validate({ required })
  private avgJobs: string = '';
  
  @Validate({ required })
  private mensagem = '';

  public mounted() {
    this.loadDataFromSession();
    this.recInterviewList = [
      this.$t('Com ranking das entrevistas').toString(),
      this.$t('Sem ranking das entrevistas').toString(),
      this.$t('Não quero entrevista gravada').toString()
    ]
    this.avgJobList = [
      this.$t('Até 2 vagas').toString(),
      this.$t('2 a 6 vagas').toString(),
      this.$t('6 a 10 vagas').toString(),
      this.$t('10 a 20 vagas').toString(),
      this.$t('mais de 20 vagas').toString(),
      this.$t('Outros').toString()
    ]
    this.yesnoList = [
      this.$t('Sim').toString(),
      this.$t('Não').toString()
    ]
  }
  public gottoPlan() {
    this.$bvModal.hide('solicitar-diagnostico-modal')
    this.$router.push({path: '/empresa/cadastro/planos', query: {lang: this.$i18n.locale }})
    return;
  }

  get isplanPage() {
    return this.$router.currentRoute.name == 'CadastroEscolherPlano_';
  }

  public loadDataFromSession() {
    const dadosEmpresaString: string|null = sessionStorage.getItem('registroEmpresa');
    let dadosEmpresa: any = undefined;
    if(dadosEmpresaString) {
      try {
        dadosEmpresa = JSON.parse(dadosEmpresaString);
        console.log(dadosEmpresa);
        this.$v.responsavel.$model = dadosEmpresa.nomeResponsavel;
        this.$v.empresaEmail.$model = dadosEmpresa.email;
        this.$v.empresaTelefone.$model = dadosEmpresa.telefone;
      } catch (e) {
        console.error(e);
      }
    }
  }

  public openModalCadastro() {
    this.$emit('registryClicked',true)
    this.$bvModal.show('cadastro-empresa-modal')
  }

  public send() {

    this.$v.mensagem.$model = ` 1. Entrevista gravada  [${this.gravada}] `;
    this.$v.mensagem.$model += ` 2. Entrevista ao vivo  [${this.aovivo}] `;
    this.$v.mensagem.$model += ` 3.  Entrevista ás cegas [${this.ascegas}] `;
    this.$v.mensagem.$model += ` 4. Qual a média de vagas você tem por mês? [${this.avgJobs}] `;
    
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }
    const data_send = {
        nome: this.responsavel,
        email: this.empresaEmail,
        telefone: this.empresaTelefone,
        assunto: ' - Monte seu plano',
        mensagem: this.mensagem
      };

      console.log(data_send)
    this.contatoService = new ContatoService();
    this.promise = this.contatoService
      .create(data_send)
      .then((data: any) => {
        this.alert.title = this.$t('Obrigado');
        this.alert.addMsg(this.$t('Seu contato foi enviado para nossa equipe, logo iremos respondê-lo.'));
        this.alert.callBoxSuccess();
        this.$emit('sended',true)
      })
      .catch((err: any) => {
        // console.log(err);
        this.$emit('sended',false)
      });
  }
  
  public succesRegistry(data: any) {
    if(this.isplanPage) {
      let frontBase = siteCompanyEndPoint;
      if (sessionStorage.getItem("access_token")) {
        if(data?.rooms?.length==0) {
          window.location.replace(
            `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
              "access_token"
            )}&redirectTo=/rooms&lang=${this.$i18n.locale}`
          );
        } else {
          const roomid: any = data?.rooms[0]?.key
          window.location.replace(
            `${frontBase}/redirectLogin?accessToken=${sessionStorage.getItem(
              "access_token"
            )}&redirectTo=/room/${roomid}&lang=${this.$i18n.locale}`
          );

        }
      }
    }
  }
  public erroRegistry(type: string){
    
   
  }
}
