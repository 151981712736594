











































import { Prop, Component, Vue } from 'vue-property-decorator';
import Section from '@/components/layouts/Section.vue';
import ContatoForm from '@/components/form/ContatoForm.vue';
import SocialMedia from '@/views/candidato/components/SocialMedia.vue';

@Component({
  components: {
    Section,
    ContatoForm,
    SocialMedia,
  },
})
export default class Contato extends Vue {
}
