
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import ClientAuthentication from "@/core/Services/ClientAuthentication";
import { CB } from '@/core/util';

export default class Pais extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/pais', method: 'POST', headers: {} },
                fetch: { url: '/v1/pais/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/pais', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/pais/{id}', method: 'PUT', headers: {} } }));
        this.optionList = [];
    }

    public fetchAll(data?: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            (new ClientAuthentication()).auth().then((rtoken: any) => {
            const dta: any = {
                rtoken,
                ...data
            };
            const cdta = CB.genC(dta);
            super.fetchAll(cdta).then(resolve).catch(reject);
            }).catch((resp) => {
                reject(resp);
            });
        });
    }

        
    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.paises) {
            this.optionList = [
                {
                    text: 'Selecione',
                    value: ''
                }
            ];
            data.paises.forEach((item: any) => {
                this.optionList!.push({
                    text: item.nome,
                    value: item.sigla,
                });
            });
        }
    }

}
