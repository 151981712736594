import { render, staticRenderFns } from "./FormMonteSeuPlano.vue?vue&type=template&id=231b9bb5&scoped=true&"
import script from "./FormMonteSeuPlano.vue?vue&type=script&lang=ts&"
export * from "./FormMonteSeuPlano.vue?vue&type=script&lang=ts&"
import style0 from "./FormMonteSeuPlano.vue?vue&type=style&index=0&id=231b9bb5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "231b9bb5",
  null
  
)

export default component.exports