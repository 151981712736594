import Vue from 'vue';
import Router, { Route } from 'vue-router';

import HomeCandidato from '@/views/candidato/Home.vue';
import HomeEmpresa from '@/views/empresa/home/Home.vue';
import RootCadastroEmpresa from '@/views/empresa/cadastro/RootCadastro.vue';
import CadastroDadosDeAcesso from '@/views/empresa/cadastro/CadastroDadosDeAcesso.vue';
import CadastroDadosDeAcessoPlural from '@/views/empresa/cadastro/CadastroDadosDeAcessoPlural.vue';
import CadastroDadosInvoice from '@/views/empresa/cadastro/CadastroDadosInvoice.vue';
import CadastroEscolherPlano from '@/views/empresa/cadastro/CadastroEscolherPlano.vue';
import CadastroPagamento from '@/views/empresa/cadastro/CadastroPagamento.vue';
import PageRedirect from '@/views/redirect/Redirect.vue';
import CadastroPagamentoSucesso from '@/views/empresa/cadastro/CadastroPagamentoSucesso.vue';
import CadastroPlanoGratuito from '@/views/empresa/cadastro/CadastroPlanoGratuito.vue';
import CadastroPlanoCustomizado from '@/views/empresa/cadastro/CadastroPlanoCustomizado.vue';
import ThirdPartyLoginSuccess from '@/components/login/ThirdPartyLoginSuccess.vue';
import TermsResponsability from '@/views/terms/TermsResponsability.vue';
import TermsPrivacy from '@/views/terms/privacy/TermsPrivacy.vue';
import { siteCompanyEndPoint, siteCandidateEndPoint } from './core/endpoint';
import { i18n } from './plugins/i18n';

const redirectToPublicCompany = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  location.href = siteCompanyEndPoint + path.replace('//', '/');
};

const redirectToPublicJob = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  location.href = siteCandidateEndPoint + path.replace('//', '/');
};

const redirectToactive = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  if (to.query!.tkn!) {
    location.href = siteCandidateEndPoint + '/login?acctkn=' + to.query.tkn + '&lang=' + i18n.locale;
  } else {
    location.href = siteCandidateEndPoint + '/login?lang=' + i18n.locale;
  }
};

const redirectToResetPassword = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  if (to.query!.tkn!) {
    location.href = siteCandidateEndPoint + '/reset?tkn=' + to.query.tkn + '&lang=' + i18n.locale;
  } else {
    location.href = siteCandidateEndPoint + '/login?lang=' + i18n.locale;
  }
};

const redirectToPublicProfile = (to: Route, from: Route, next: any) => {
  const path = to.fullPath;
  location.href = siteCandidateEndPoint + path.replace('//', '/');
};

const metaTags: any = [
  {
    name: 'description',
    content: '__description',
  },
  {
    name: 'keywords',
    content: '__keywords',
  },
  {
    property: 'og:description',
    content: '__description'
  }
];

Vue.use(Router);

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      // path: '/',
      path: '/candidato',
      name: 'HomeCandidato',
      meta: { title: 'Aperte o play e preencha a vaga!', metaTags },
      component: HomeCandidato,
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';

        next();
      },
      // No prerender o locale já vai ser en
    },
    {
      // path: '/pt',
      path: '/candidato/pt',
      name: 'HomeCandidato-pt',
      meta: { title: 'Aperte o play e preencha a vaga!', metaTags },
      component: HomeCandidato,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      // path: '/en',
      path: '/candidato/en',
      name: 'HomeCandidato-en',
      meta: { title: 'Aperte o play e preencha a vaga!', metaTags },
      component: HomeCandidato,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'en';
        next();
      },
    },
    {
      // path: '/es',
      path: '/candidato/es',
      name: 'HomeCandidato-es',
      meta: { title: 'Aperte o play e preencha a vaga!', metaTags },
      component: HomeCandidato,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'es';
        next();
      },
    },
    {
      path: '/3party',
      name: 'ThirdPartyLoginSuccess',
      component: ThirdPartyLoginSuccess,
      // beforeEnter: setLanguage
    },
    {
      path: '/public/candidatura/view',
      name: 'PublicInterview',
      beforeEnter: redirectToPublicCompany,
    },
    {
      path: '/candidato/activeacc',
      name: 'CandidateActiveAccount',
      beforeEnter: redirectToactive,
    },
    {
      path: '/candidato/resetpass',
      name: 'CandidateResetAccount',
      beforeEnter: redirectToResetPassword,
    },
    {
      path: '/v/:urlcode',
      name: 'PublicVaga',
      beforeEnter: redirectToPublicJob,
    },
    {
      path: '/c/:urlcode',
      name: 'PublicCandidateProfile',
      beforeEnter: redirectToPublicProfile,
    },
    {
      path: '//v/:urlcode',
      name: 'PublicVaga_',
      beforeEnter: redirectToPublicJob,
    },
    {
      path: '/invite/:urlcode',
      name: 'PublicVaga_i',
      beforeEnter: redirectToPublicJob,
    },
    {
      path: '//invite/:urlcode',
      name: 'PublicVaga_i_',
      beforeEnter: redirectToPublicJob,
    },
    {
      // path: '/empresa',
      path: '/',
      name: 'HomeEmpresa',
      component: HomeEmpresa,
      meta: { title: 'Para Empresas', metaTags },
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      // path: '/empresa/en',
      path: '/en',
      name: 'HomeEmpresa-en',
      component: HomeEmpresa,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'en';
        next();
      },
    },
    {
      // path: '/empresa/en',
      path: '/pt',
      name: 'HomeEmpresa-pt',
      component: HomeEmpresa,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      // path: '/empresa/es',
      path: '/es',
      name: 'HomeEmpresa-es',
      component: HomeEmpresa,
      beforeEnter: (to, from, next) => {
        document.documentElement.lang = i18n.locale = 'es';
        next();
      },
    },
    {
      path: '/termos-empresa',
      name: 'TermsResponsability',
      component: TermsResponsability,
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      path: '/redirect',
      name: 'PageRedirect',
      component: PageRedirect,
      props: true,
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      path: '/terms',
      name: 'TermsPrivacy',
      component: TermsPrivacy,
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },
    },
    {
      path: '/empresa/pagamento-sucesso',
      name: 'CadastroPagamentoSucesso',
      component: CadastroPagamentoSucesso,
    },
    {
      path: '/cadastro-plural',
      name: 'CadastroDadosDeAcessoPlural',
      component: CadastroDadosDeAcessoPlural,
    },
    {
      path: '/empresa/cadastro',
      component: RootCadastroEmpresa,
      beforeEnter: (to, from, next) => {
        if (!to.query.lang)
          document.documentElement.lang = i18n.locale = 'pt-BR';
        next();
      },

      children: [
        {
          path: '/',
          redirect: 'informacoes',
        },
        {
          path: 'planos',
          name: 'CadastroEscolherPlano_',
          component: CadastroEscolherPlano,
        },
        {
          path: 'dados-de-acesso',
          name: 'CadastroDadosDeAcesso',
          component: CadastroDadosDeAcesso,
        },
        {
          path: 'dados-de-invoice',
          name: 'CadastroDadosInvoice',
          component: CadastroDadosInvoice,
        },
        {
          path: 'escolher-plano',
          name: 'CadastroEscolherPlano',
          component: CadastroEscolherPlano,
        },
        {
          path: 'plano-gratuito',
          name: 'CadastroPlanoGratuito',
          component: CadastroPlanoGratuito,
        },
        {
          path: 'plano-customizado',
          name: 'CadastroPlanoCustomizado',
          component: CadastroPlanoCustomizado,
        },
        {
          path: 'pagamento',
          name: 'CadastroPagamento',
          component: CadastroPagamento,
        },
      ],
    },
  ],
});

// tslint:disable-next-line: ban-types
router.beforeEach((to: Route, from: Route, next: Function) => {

  // const lang: string = !to.query.lang ? navigator.language : to.query.lang + '' ;
  const lang: string = !to.query.lang ? 'pt-BR' : to.query.lang + '' ;

  const locale = lang.substring(0, 2);

  if (['en', 'es', 'pt'].indexOf(locale) >= 0) {
    if (locale === 'pt') {
      i18n.locale = 'pt-BR';
    } else {
      i18n.locale = locale;
    }
  } else {
    i18n.locale = 'en-US';
  }
  document.documentElement.lang = i18n.locale;

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = 'Jobecam - ' + i18n.t(nearestWithTitle.meta.title).toString();
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el: any) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      let tag: any | null = document.querySelector(`meta[name="${tagDef.name}"]`);
      if (!tag)
        tag = document.querySelector(`meta[property="${tagDef.property}"]`);
      if (!tag)
        tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key: any) => {
        tag.setAttribute(key, i18n.t(tagDef[key]).toString());
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag: any) => document.head.appendChild(tag));

  next();
});

export default router;
