




































































import { Component, Vue, Prop } from 'vue-property-decorator';
import SocialMedia from '@/views/candidato/components/SocialMedia.vue';
import LangSelector from '@/components/LangSelector.vue';
import PageRedirect from '@/views/redirect/Redirect.vue';
import {siteCompanyEndPoint,siteCandidateEndPoint} from '@/core/endpoint';

@Component({
  components: {
    SocialMedia,
    LangSelector,
  },
})
export default class Header extends Vue {

  @Prop({default: false})
  public companyPage!: boolean;
  
  public empresaURL = siteCompanyEndPoint;
  public candidatoURL = siteCandidateEndPoint;

  private get inEmpresaPage() {
    return (this.companyPage || (this.$route.name+"").indexOf('HomeEmpresa') > -1);
  }

  public loginEmpresa(){
    let sUrl = '';
    
    if(this.inEmpresaPage){
      sUrl = this.empresaURL+'?lang='+this.$i18n.locale;
    }else{
      sUrl = this.candidatoURL+'?lang='+this.$i18n.locale;
    }
    window.location.replace(sUrl);
  }
}
